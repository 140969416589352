import React from "react";
import Helmet from "react-helmet";

import Layout from "src/layouts/LayoutWhiteHeader";
import config from "data/SiteConfig";
import ContactMeForm from "src/components/generic/ContactMeForm/ContactMeForm";

// eslint-disable-next-line react/prefer-stateless-function
class ContactMeForTraining extends React.Component {
	render() {
		return (
			<Layout>
				<main>
					<Helmet
						title={`Contact Me - Training | ${config.siteTitle}`}
					/>
				</main>
				<div className="container">
					<div className="columns">
						<div className="column is-half">
							<h2>Training</h2>
							<p>
								If you&#39;re interested in training for your
								team or yourself, fill in the form and I&#39;ll
								ping you an email so we can talk through all the
								nitty gritty details.
							</p>
							<p>And thank you!</p>
							<ContactMeForm formName="training" />
						</div>
					</div>
				</div>
			</Layout>
		);
	}
}

export default ContactMeForTraining;
